
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
  computed,
  reactive,
  toRefs,
  watch,
  h,
} from "vue";
import { ColumnsType } from "ant-design-vue/lib/table";
import {
  KolRewardRecordInfo,
  GetKolRewardRecordListRes,
} from "@lctech-tw/jkface-proto/dist/js/face-front-api/ticket_pb";
import { timestampToDayjs } from "@/lib/utils";
import { apiJkface } from "@/lib/api_jkface";
import { clientStore } from "@/store/client";
import router from "@/router";

class IncomeOverview {
  // 訂單數量
  amount: number;
  // 訂單總金額
  sales: number;
  // 總分潤金額
  profit: number;
  // 退單數量
  refundAmount: number;
  // 退單總金額
  refundSales: number;
  // 總退單金額
  refundProfit: number;
  constructor(res: GetKolRewardRecordListRes) {
    this.amount = res.getAmount();
    this.sales = res.getSales();
    this.profit = res.getProfit();
    this.refundAmount = res.getRefundAmount();
    this.refundProfit = res.getRefundProfit();
    this.refundSales = res.getRefundSales();
  }
}
class IncomeLog {
  id: number;
  // 訂單編號
  orderNumber: string;
  // 商品名稱
  ticketName: string;
  // 用戶名稱
  userDisplayname: string;
  // 數量
  amount: number;
  // 收益
  reward: number;
  // 創建時間
  createdAt: string;
  // 更新時間
  updatedAt: string;
  // KOL名稱
  kolName: string;
  // 狀態, 1: 已購買, 2:已取票, -2: 已退票
  status: number;
  constructor(record: KolRewardRecordInfo) {
    this.orderNumber = record.getOrderNo();
    this.ticketName = record.getTicketName();
    this.userDisplayname = record.getUserDisplayName();
    this.amount = record.getAmount();
    this.reward = record.getReward();
    this.createdAt =
      timestampToDayjs(record.getCreatedAt())?.format(
        "YYYY年M月D日 HH:mm:ss"
      ) || "-";
    this.updatedAt =
      timestampToDayjs(record.getUpdatedAt())?.format(
        "YYYY年M月D日 HH:mm:ss"
      ) || "-";
    this.kolName = record.getKolName();
    this.id = record.getId();
    this.status = record.getStatus();
  }
}
class KolPerson {
  name: string;
  inviteCodeId: number;
  constructor(name: string, inviteCodeId: number) {
    this.name = name;
    this.inviteCodeId = inviteCodeId;
  }
}
class TicketDiscountItem {
  id: number;
  name: string;
  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}
export default defineComponent({
  name: "",
  setup() {
    const kolList: Ref<KolPerson[]> = ref([]);
    const ticketDiscountItemList: Ref<TicketDiscountItem[]> = ref([]);

    const state = reactive({
      isLoading: false,
      isFetchingLogs: false,
    });
    const params = reactive({
      current: 1,
      total: 0,
      pageSize: 50,
      showSizeChanger: false,
      ticketDiscountId: -1,
    });
    const searchInviteCodeId: Ref<number> = ref(0);
    const columns: ColumnsType = [
      {
        title: "訂單編號",
        dataIndex: "orderNumber",
      },
      {
        title: "商品名稱",
        dataIndex: "ticketName",
      },
      {
        title: "用戶名稱",
        dataIndex: "userDisplayname",
      },
      {
        title: "數量",
        dataIndex: "amount",
      },
      {
        title: "收益",
        dataIndex: "reward",
      },
      {
        title: "狀態",
        dataIndex: "status",
      },
      {
        title: "創建時間",
        dataIndex: "createdAt",
      },
      {
        title: "更新時間",
        dataIndex: "updatedAt",
      },
      {
        title: "KOL名稱",
        dataIndex: "kolName",
        customFilterDropdown: true,
      },
    ];
    const incomeOverview: Ref<IncomeOverview | null> = ref(null);
    const incomeLogs: Ref<IncomeLog[]> = ref([]);
    const init = async () => {
      await getTicketDiscountList();
      params.ticketDiscountId = ticketDiscountItemList.value[0].id;
      await getLogs(1);
      await getKolList();
    };
    const getLogs = async (page: number) => {
      params.current = page;
      const res = await apiJkface.GetKolRewardRecords(
        {
          page: page,
          limit: params.pageSize,
          ticketDiscountId: params.ticketDiscountId,
          inviteCodeId:
            searchInviteCodeId.value === 0
              ? undefined
              : searchInviteCodeId.value,
        },
        clientStore.accessToken.value
      );
      params.total = res.getTotal();
      incomeOverview.value = new IncomeOverview(res);
      incomeLogs.value = res
        .getKolRewardRecordsList()
        .map((e) => new IncomeLog(e));
    };
    const onTableChange = async (
      pagination: any,
      filters: any,
      sorter: { field: string; order: "ascend" | "descend" }
    ) => {
      await getLogs(pagination.current);
    };
    const onTicketDiscountChange = async (id: number) => {
      params.ticketDiscountId = id;
      searchInviteCodeId.value = 0;
      await getKolList();
      await getLogs(1);
    };
    const getKolList = async () => {
      kolList.value = await apiJkface
        .GetKolList(
          { ticketDiscountId: params.ticketDiscountId },
          clientStore.accessToken.value
        )
        .then((res) =>
          res
            .getKolListList()
            .map((e) => new KolPerson(e.getName(), e.getInviteCodeId()))
        );
    };
    const getTicketDiscountList = async () => {
      ticketDiscountItemList.value = await apiJkface
        .GetTicketDiscounts(clientStore.accessToken.value)
        .then((res) =>
          res
            .getInfoList()
            .map(
              (e) =>
                new TicketDiscountItem(e.getTicketDiscountId(), e.getName())
            )
        );
    };

    onMounted(() => {
      init();
    });
    const isLogin = clientStore.isLogin;
    const logout = () => {
      clientStore.clearLoginSession();
      router.replace({ name: "ClientLogin" });
    };

    interface HumanStatus {
      text: string;
      color: "black" | "red";
    }
    const humanStatus = (statusCode: number): HumanStatus => {
      // 狀態, 1: 已購買, 2:已取票, -2: 已退票
      const defaultStatus: HumanStatus = { text: "unknown", color: "black" };
      const statusMap = new Map<number, HumanStatus>([
        [
          1,
          {
            text: "已購買",
            color: "black",
          },
        ],
        [
          2,
          {
            text: "已取票",
            color: "black",
          },
        ],
        [
          -2,
          {
            text: "已退票",
            color: "red",
          },
        ],
      ]);
      return statusMap.get(statusCode) || defaultStatus;
    };

    return {
      columns,
      IncomeLog,
      incomeLogs,
      incomeOverview,
      params,
      state,
      onTableChange,
      onTicketDiscountChange,
      isLogin,
      logout,
      kolList,
      searchInviteCodeId,
      getLogs,
      humanStatus,
      ticketdiscountItemList: ticketDiscountItemList,
    };
  },
});
