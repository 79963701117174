import { TicketServicePromiseClient } from "@lctech-tw/jkface-proto/dist/js/face-front-api/ticket_grpc_web_pb";
import * as ticketPb from "@lctech-tw/jkface-proto/dist/js/face-front-api/ticket_pb";

const service = () => {
  const client = new TicketServicePromiseClient(
    process.env.VUE_APP_JKFACE_HOST || ""
  );
  const enableDevTools =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__GRPCWEB_DEVTOOLS__ ||
    (() => {
      return;
    });
  enableDevTools([client]);
  return client;
};

const GetKolRewardRecords = async (
  payload: {
    page: number;
    limit: number;
    ticketDiscountId: number;
    inviteCodeId?: number;
  },
  accessToken: string
): Promise<ticketPb.GetKolRewardRecordListRes> => {
  const req: ticketPb.GetKolRewardRecordListReq =
    new ticketPb.GetKolRewardRecordListReq();
  let res: ticketPb.GetKolRewardRecordListRes;
  req.setAccessToken(accessToken);
  req.setLimit(payload.limit);
  req.setPage(payload.page);
  req.setTicketDiscountId(payload.ticketDiscountId);
  payload.inviteCodeId && req.setInviteCodeId(payload.inviteCodeId);
  try {
    res = await service().getKolRewardRecordList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const GetKolList = async (
  payload: {
    ticketDiscountId: number;
  },
  accessToken: string
): Promise<ticketPb.GetKolListRes> => {
  const req: ticketPb.GetKolListReq = new ticketPb.GetKolListReq();
  let res: ticketPb.GetKolListRes;
  req.setAccessToken(accessToken);
  req.setTicketDiscountId(payload.ticketDiscountId);
  try {
    res = await service().getKolList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const GetTicketDiscounts = async (
  accessToken: string
): Promise<ticketPb.GetTicketDiscountsRes> => {
  const req: ticketPb.GetTicketDiscountsReq =
    new ticketPb.GetTicketDiscountsReq();
  let res: ticketPb.GetTicketDiscountsRes;
  req.setAccessToken(accessToken);
  try {
    res = await service().getTicketDiscounts(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

export const apiJkface = {
  GetKolRewardRecords,
  GetKolList,
  GetTicketDiscounts,
};
