import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f491a4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "income_head" }
const _hoisted_2 = { class: "income_body" }
const _hoisted_3 = { style: {"padding":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_statistic = _resolveComponent("a-statistic")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_page_header, {
        title: "收入總覽",
        style: {"border":"1px solid rgb(235, 237, 240)","width":"50%"}
      }, {
        tags: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticketdiscountItemList, (item) => {
            return (_openBlock(), _createBlock(_component_a_tag, {
              color: _ctx.params.ticketDiscountId === item.id ? 'blue' : 'red',
              key: item.id,
              style: {"cursor":"pointer"},
              onClick: ($event: any) => (_ctx.onTicketDiscountChange(item.id))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["color", "onClick"]))
          }), 128))
        ]),
        extra: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "1",
            type: "primary",
            onClick: _ctx.logout
          }, {
            default: _withCtx(() => [
              _createTextVNode("登出")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_statistic, {
                    title: "你的訂單數量",
                    value: _ctx.incomeOverview?.amount
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_statistic, {
                    title: "訂單總金額",
                    prefix: "$",
                    value: _ctx.incomeOverview?.sales
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_statistic, {
                    title: "總分潤金額",
                    prefix: "$",
                    value: _ctx.incomeOverview?.profit
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_statistic, {
                    title: "退單數量",
                    value: _ctx.incomeOverview?.refundAmount
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_statistic, {
                    title: "退單總金額",
                    prefix: "$",
                    value: _ctx.incomeOverview?.refundSales
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_statistic, {
                    title: "總退單金額",
                    prefix: "$",
                    value: _ctx.incomeOverview?.refundProfit
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_table, {
        columns: _ctx.columns,
        "data-source": _ctx.incomeLogs,
        pagination: _ctx.params,
        loading: _ctx.state.isLoading,
        onChange: _ctx.onTableChange
      }, {
        bodyCell: _withCtx(({ column, record }: { column: any, record: IncomeLog }) => [
          (column.dataIndex === 'reward')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(" $ " + _toDisplayString(record.reward.toLocaleString()), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'status')
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                style: _normalizeStyle({ color: _ctx.humanStatus(record.status).color })
              }, _toDisplayString(_ctx.humanStatus(record.status).text), 5))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_radio_group, {
              value: _ctx.searchInviteCodeId,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInviteCodeId) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getLogs(_ctx.params.current)))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kolList, (kol, index) => {
                  return (_openBlock(), _createBlock(_component_a_radio, {
                    value: kol.inviteCodeId,
                    key: index,
                    style: { display: 'flex', height: '30px', lineHeight: '30px' }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(kol.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128)),
                _createVNode(_component_a_radio, {
                  value: '',
                  style: { display: 'flex', height: '30px', lineHeight: '30px' }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("無篩選")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ])
        ]),
        _: 1
      }, 8, ["columns", "data-source", "pagination", "loading", "onChange"])
    ])
  ], 64))
}